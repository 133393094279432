import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  const url = "https://app.instagantt.com/shared/s/6msYNIgH2mvZX2y2Yn4Q/latest";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
